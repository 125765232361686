.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  background: #1d1d20;
  border: 1px solid #39ffd0;
  border-radius: 10px;
  box-shadow: 24;
  padding: 10px;
  font-family: 'Poppins', sans-serif;
  color: white;
  z-index: 1000;
}

.close-icon {
  margin-top: 2px;
  margin-right: 5px;
  padding: 10px;
  align-self: flex-end;
  color: #f557ef;
  text-decoration: none;
  border-radius: 50%;
  background: #1d1d20;
}

.modal-title {
  align-self: center;
  margin-top: 15px;
}

.subtitle {
  align-self: center;
  margin-top: 30px;
  padding-bottom: 40px;
}

.close-icon:hover {
  cursor: pointer;
}
