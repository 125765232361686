.credit-links {
  display: 'flex';
  font-family: 'Poppins', 'sans serif';
  height: fit-content;
  color: 'antiquewhite';
  text-decoration: none;
  font-size: xx-small;
  background-color: #262627;
  padding: 8px;
  margin: auto;
}

.links {
  text-decoration: 'none';
  color: antiquewhite;
}

.links:link {
  text-decoration: none;
}

.by {
  color: antiquewhite;
  width: fit-content;
  display: inline;
}
