.form-body {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
}

.inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* .message-and-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
} */
.btn-container {
  width: auto;
  display: flex;
  justify-content: center;
}

textarea {
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  gap: 20px;
  width: auto;
  height: 100px;
  resize: none;
}

input {
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  height: 44px;
  background: #ffffff;
  border-radius: 4px;
}

input,
textarea {
  margin: 20px;
  border-radius: 3px;
  box-shadow: none;
}

.btn-submit {
  font-family: 'Poppins', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 20px 0px 20px 0px;
  gap: 10px;
  cursor: pointer;
  width: 60%;
  height: 55px;
  text-align: center;
  font-size: large;
  color: white;
  background: linear-gradient(
    99.18deg,
    #39ffd0 -16.56%,
    #5446f1 49.21%,
    #f557ef 130.61%
  );
  border-radius: 300px;
}

.btn-submit:disabled {
  opacity: 0.2;
}

.invalid input,
.invalid textarea {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

.invalid input:focus,
.invalid textarea:focus {
  border-color: #ff8800;
  background-color: #fbe8d2;
}

.error-text {
  color: #b40e0e;
}

@media only screen and (min-width: 1024px) {
  .form-body {
    width: 50%;
  }
  .form {
    display: flex;
    justify-content: center;
  }
}
