.projects-body {
  height: auto;
  background-color: #1d1d20;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
}

.link-container {
  display: flex;
  justify-content: flex-end;
}
.page-title-container {
  background-image: url('../images/page-backgrounds/blackboard.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 200px;
}
.work-pitch {
  color: white;
  margin: 5%;
  padding: 3px;
}
