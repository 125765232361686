@import url(https://fonts.googleapis.com/css2?family=Righteous&family=Roboto+Condensed&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@200;300&family=Righteous&family=Roboto+Condensed&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fjalla+One&family=Poppins:wght@200&family=Quicksand:wght@300&family=Secular+One&family=Ubuntu:wght@300&display=swap);
.title {
  width: 146px;
  height: 54px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 54px;
  color: #ffffff;
  margin-left: 5%;
  margin-bottom: 0px;
  padding: 3px;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 30px;
  height: 30px;
border: 10px solid #5446f1;
  border-top: 10px solid #1d1d20;

  border-radius: 50%;
  -webkit-animation: spinner 1.5s linear infinite;
          animation: spinner 1.5s linear infinite;
}


.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 300px;
  background: #1d1d20;
  border: 1px solid #39ffd0;
  border-radius: 10px;
  box-shadow: 24;
  padding: 10px;
  font-family: 'Poppins', sans-serif;
  color: white;
  z-index: 1000;
}

.close-icon {
  margin-top: 2px;
  margin-right: 5px;
  padding: 10px;
  align-self: flex-end;
  color: #f557ef;
  text-decoration: none;
  border-radius: 50%;
  background: #1d1d20;
}

.modal-title {
  align-self: center;
  margin-top: 15px;
}

.subtitle {
  align-self: center;
  margin-top: 30px;
  padding-bottom: 40px;
}

.close-icon:hover {
  cursor: pointer;
}

.form-body {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
}

.inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* .message-and-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
} */
.btn-container {
  width: auto;
  display: flex;
  justify-content: center;
}

textarea {
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  grid-gap: 20px;
  gap: 20px;
  width: auto;
  height: 100px;
  resize: none;
}

input {
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  grid-gap: 10px;
  gap: 10px;
  padding: 10px;
  height: 44px;
  background: #ffffff;
  border-radius: 4px;
}

input,
textarea {
  margin: 20px;
  border-radius: 3px;
  box-shadow: none;
}

.btn-submit {
  font-family: 'Poppins', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 20px 0px 20px 0px;
  grid-gap: 10px;
  gap: 10px;
  cursor: pointer;
  width: 60%;
  height: 55px;
  text-align: center;
  font-size: large;
  color: white;
  background: linear-gradient(
    99.18deg,
    #39ffd0 -16.56%,
    #5446f1 49.21%,
    #f557ef 130.61%
  );
  border-radius: 300px;
}

.btn-submit:disabled {
  opacity: 0.2;
}

.invalid input,
.invalid textarea {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

.invalid input:focus,
.invalid textarea:focus {
  border-color: #ff8800;
  background-color: #fbe8d2;
}

.error-text {
  color: #b40e0e;
}

@media only screen and (min-width: 1024px) {
  .form-body {
    width: 50%;
  }
  .form {
    display: flex;
    justify-content: center;
  }
}

.modal-open-body {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
}
.contact-body {
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  background-color: #1d1d20;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.title-and-tag {
  display: flex;
  flex-direction: column;
  background-image: url(/static/media/laptop.7f5552f3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 200px;
  color: white;
}
.contact-tag {
  margin-left: 10px;
  padding-top: 40px;
}
.link-container-contact {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
}
.contact-text {
  color: white;
  margin: 5%;
}

.cv-btn {
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  background-color: #1d1d20;
  cursor: pointer;
  display: flex;
  border: none;
  margin-top: 8px;
}
.cv-btn:hover {
  color: #f557ef;
}
.socials-and-cv {
  display: flex;
  justify-content: center;
  margin: 10% 0px 10% 0px;
}

@media only screen and (min-width: 1400px) {
  .contact-body {
    width: 100vw;

    /* display: grid;
    grid-template-columns: 25% 50% 25%;
    grid-template-rows: repeat(4, 25%);
    grid-template-areas:
      't-and-t t-and-t t-and-t'
      'contact-text contact-text contact-text'
      '. form .'
      '. . links'; */
  }
  .title-and-tag {
    grid-area: t-and-t;
  }

  .link-container-contact {
    display: flex;
    justify-content: flex-end;
    grid-area: links;
    align-items: flex-end;
  }

  .contact-text {
    grid-area: contact-text;
    height: 10%;
  }
  .segment-links-contact-top,
  .segment-links-contact-work {
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    color: #595958;
    cursor: pointer;
    display: flex;
    margin: 15px;
    padding: 10px;
    height: 20px;
  }

  .segment-links-contact-top:hover,
  .segment-links-contact-work:hover {
    color: white;
  }

  .form {
    display: flex;
    justify-content: center;
    justify-self: center;
  }
}

.segment-links {
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  color: #595958;
  cursor: pointer;
  display: flex;
  margin: 15px;
  padding: 10px;
}

.segment-links:hover {
  color: #39ffd0;
}

.segment-links:active {
  color: white;
}

.arrow-up, .arrow-down {
  margin-right: 5px;
}

 .socials-links-container {
  background-color: transparent;
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
  /* position: fixed;
  right: 0px;
  top: 5px; */
} 

.socials-links-buttons {
  background-color: #1d1d20;
  border: none;
  cursor: pointer;
  margin: 0px 5px 0px 5px;
} 

.credit-links {
  display: 'flex';
  font-family: 'Poppins', 'sans serif';
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  color: 'antiquewhite';
  text-decoration: none;
  font-size: xx-small;
  background-color: #262627;
  padding: 8px;
  margin: auto;
}

.links {
  -webkit-text-decoration: 'none';
          text-decoration: 'none';
  color: antiquewhite;
}

.links:link {
  text-decoration: none;
}

.by {
  color: antiquewhite;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline;
}

.projects-body {
  height: auto;
  background-color: #1d1d20;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
}

.link-container {
  display: flex;
  justify-content: flex-end;
}
.page-title-container {
  background-image: url(/static/media/blackboard.2e2923e7.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 200px;
}
.work-pitch {
  color: white;
  margin: 5%;
  padding: 3px;
}


body {
    margin: 0px;

}

