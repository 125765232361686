.title {
  width: 146px;
  height: 54px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 54px;
  color: #ffffff;
  margin-left: 5%;
  margin-bottom: 0px;
  padding: 3px;
}
