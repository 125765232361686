.modal-open-body {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
}