.contact-body {
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  background-color: #1d1d20;
  height: fit-content;
}

.title-and-tag {
  display: flex;
  flex-direction: column;
  background-image: url('../images/page-backgrounds/laptop.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 200px;
  color: white;
}
.contact-tag {
  margin-left: 10px;
  padding-top: 40px;
}
.link-container-contact {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
}
.contact-text {
  color: white;
  margin: 5%;
}

.cv-btn {
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  background-color: #1d1d20;
  cursor: pointer;
  display: flex;
  border: none;
  margin-top: 8px;
}
.cv-btn:hover {
  color: #f557ef;
}
.socials-and-cv {
  display: flex;
  justify-content: center;
  margin: 10% 0px 10% 0px;
}

@media only screen and (min-width: 1400px) {
  .contact-body {
    width: 100vw;

    /* display: grid;
    grid-template-columns: 25% 50% 25%;
    grid-template-rows: repeat(4, 25%);
    grid-template-areas:
      't-and-t t-and-t t-and-t'
      'contact-text contact-text contact-text'
      '. form .'
      '. . links'; */
  }
  .title-and-tag {
    grid-area: t-and-t;
  }

  .link-container-contact {
    display: flex;
    justify-content: flex-end;
    grid-area: links;
    align-items: flex-end;
  }

  .contact-text {
    grid-area: contact-text;
    height: 10%;
  }
  .segment-links-contact-top,
  .segment-links-contact-work {
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    color: #595958;
    cursor: pointer;
    display: flex;
    margin: 15px;
    padding: 10px;
    height: 20px;
  }

  .segment-links-contact-top:hover,
  .segment-links-contact-work:hover {
    color: white;
  }

  .form {
    display: flex;
    justify-content: center;
    justify-self: center;
  }
}
