.segment-links {
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  color: #595958;
  cursor: pointer;
  display: flex;
  margin: 15px;
  padding: 10px;
}

.segment-links:hover {
  color: #39ffd0;
}

.segment-links:active {
  color: white;
}

.arrow-up, .arrow-down {
  margin-right: 5px;
}
