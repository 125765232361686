 .socials-links-container {
  background-color: transparent;
  width:fit-content;
  /* position: fixed;
  right: 0px;
  top: 5px; */
} 

.socials-links-buttons {
  background-color: #1d1d20;
  border: none;
  cursor: pointer;
  margin: 0px 5px 0px 5px;
} 
